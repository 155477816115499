import { useContext } from 'react'
import { LanguageContext } from './languageContext'
import { dictionaryGlobal } from 'apis/dictionary/global'

export default function useMultilang() {
    const { translations, language, setSelectedLanguage, app } = useContext(LanguageContext)

    const getT = (key) => {
      if(translations[key] == null) {
        dictionaryGlobal.addTranslation(key, app).then(res => {
          if(res.success) {
            //console.log(res)
          } else {
            //console.log(res)
          }
        })
      }
      return ((translations[key] && translations[key] !== "") ? translations[key] : key)
    }

    const getName = (name, defTransKey) => {
      if (name == null && defTransKey == null) return ''
      let nameStr = name?.[language] || name?.['en'] || name?.['hu']
      return nameStr || 'N/A'
    }

    return {
      language,
      translations,
      getT,
      getName,
      setSelectedLanguage
    }
} 