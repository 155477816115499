import { useState } from "react";

function NumPad(props) {
  const { className, onSubmit } = props;

  const [pin, setPin] = useState("");

  const handleSubmit = () => {
      if(pin !== "" && typeof onSubmit === "function"){
          onSubmit(pin)
          setPin("")
      }
  };

  const handleButton = (e) =>{
    setPin(pin+""+e)
  }

  const handleDelete = ( ) =>{
      setPin(pin.slice(0, pin.length-1))
  }


  return (
    <div className={`${className ? className : ""}`}>
      <div className="pin-container">{pin}</div>
      <div className={`login-pin-numbers `}>
        <div className="login-button" onClick={()=>{handleButton(1)}}><div className="inner-container">1</div></div>
        <div className="login-button" onClick={()=>{handleButton(2)}}><div className="inner-container">2</div></div>
        <div className="login-button" onClick={()=>{handleButton(3)}}><div className="inner-container">3</div></div>
        <div className="login-button" onClick={()=>{handleButton(4)}}><div className="inner-container">4</div></div>
        <div className="login-button" onClick={()=>{handleButton(5)}}><div className="inner-container">5</div></div>
        <div className="login-button" onClick={()=>{handleButton(6)}}><div className="inner-container">6</div></div>
        <div className="login-button" onClick={()=>{handleButton(7)}}><div className="inner-container">7</div></div>
        <div className="login-button" onClick={()=>{handleButton(8)}}><div className="inner-container">8</div></div>
        <div className="login-button" onClick={()=>{handleButton(9)}}><div className="inner-container">9</div></div>
        <div className="login-button" onClick={handleDelete}><div className="inner-container">{"<"}</div></div>
        <div className="login-button" onClick={()=>{handleButton(0)}}><div className="inner-container">0</div></div>
        <div className="login-button" onClick={handleSubmit}><div className="inner-container">OK</div></div>
      </div>
    </div>
  );
}

export default NumPad;
