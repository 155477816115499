import React from 'react'
import { useNavigate } from 'react-router-dom'

function ErrorFallback({error, resetErrorBoundary}) {

    const navigate = useNavigate()

    let title = localStorage['selectedLanguage'] === 'hu' ? "Hiba történt" : "Error"
    let message = localStorage['selectedLanguage'] === 'hu' ? "Valami itt nem stimmel..." : "Something went wrong..."
    let tryAgain = localStorage['selectedLanguage'] === 'hu' ? "Újra próbálom" : "Try again"
    let back = localStorage['selectedLanguage'] === 'hu' ? "Vissza" : "Back"
    let reset = localStorage['selectedLanguage'] === 'hu' ? "App újraindítása" : "Reset app"

    console.error(error)

    const resetApp = () => {
        navigate('/')
        window.location.reload()
    }

    return(
        <div className="modal error-modal">
            <div className="error-modal-title">{title}</div>
            <div className="error-modal-message">{message}</div>
            <div className="error-modal-actions">
                <div className="error-modal-action try-again" onClick={resetErrorBoundary}>{tryAgain}</div>
                <div className="error-modal-action go-back" onClick={() => { navigate(-1) }}>{back}</div>
                <div className="error-modal-action reset-app" onClick={resetApp}>{reset}</div>
            </div>
        </div>
    )
}

export { ErrorFallback }
